@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.skill-card {
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.skill-card.visible {
  opacity: 1;
  animation: fadeInUp 0.6s ease-out forwards;
}

.App {
  text-align: center;
}

#elementOnTopOfCanvas {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 11;
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
  overflow: auto;
  font-family: "Lato", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}
